import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const CookiesPage = () => (
    <Layout>
        <Seo title={'Cookies Policy | Nemo Personal Finance'} description={'Details of how the Nemo Personal Finance website uses cookies to track your journey around our site and information on the various cookies that we currently use.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Cookies</h1>
                            </div>
                            <div className="bot"></div>
                        </div>

                        <div className="intro">
                            <p className="leading">INFORMATION ABOUT OUR USE OF COOKIES</p>
                        </div>
                    </div>
                    <p>
                        Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.
                    </p>
                    <p>
                        A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.<br /><br />We use the following cookies: <br /><br />
                        •&nbsp;<strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.<br /><br />
                        {/*•&nbsp;<strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily. <br /><br />*/}
                        {/*•&nbsp;<strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you and remember your preferences (for example, your choice of language or region). <br /><br />*/}
                        {/*•&nbsp;<strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and any advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose. <br /><br />*/}
                        You can find more information about the individual cookies we use and the purposes for which we use them in the table below:
                    </p>
                    <p className="leading">
                    </p>
                    <p className="leading">
                    </p>
                    <table>
                        <tbody>
                        <tr>
                            <td><p><strong>Cookie set
                                by&nbsp;</strong>&nbsp;&nbsp; </p></td>
                            <td><p>&nbsp;<strong>Name </strong></p></td>
                            <td>
                                <strong>Duration&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;</strong>
                            </td>
                            <td><p>&nbsp;<strong>Purpose</strong></p></td>
                            <td><p>&nbsp;<strong>More Information</strong></p></td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td><p>Google Analytics</p></td>*/}
                        {/*    <td><p>_utma&nbsp;&nbsp;&nbsp;</p><p>&nbsp;</p><p>_utmb</p>*/}
                        {/*        <p>&nbsp;</p><p>_utmz</p><p>&nbsp;</p><p>_utmc</p></td>*/}
                        {/*    <td><p>30 days.</p><p>&nbsp;</p><p>30 minutes.</p><p>&nbsp;</p><p>30 days.</p><p>&nbsp;</p><p>Session based (expires when you&nbsp;leave the*/}
                        {/*        Nemo website). </p></td>*/}
                        {/*    <td><p>These cookies are used to collect information about how users reached the Nemo*/}
                        {/*        website and the journey taken whilst you are here, including your activity on certain*/}
                        {/*        pages. </p><p>&nbsp;</p><p>Nemo uses this information to compile reports and help us*/}
                        {/*        improve the website. The cookies collect information in an anonymous format, including*/}
                        {/*        the number of visitors to the site, how visitors reached the site and the pages they*/}
                        {/*        visited.</p><p>&nbsp;</p><p>From time to time Google’s engineers and technical staff may*/}
                        {/*        need to access Nemo’s Google Analytics account data for quality or maintenance purposes.*/}
                        {/*        Nemo may also have the option to give Google Analytics employees in support,*/}
                        {/*        optimisation and sales access to our account data if we have a problem or question*/}
                        {/*        related to our account's data.</p><p>&nbsp;</p><p>The Google Analytics Terms of Service,*/}
                        {/*        which all Analytics customers must adhere to, prohibits the tracking or collection of*/}
                        {/*        personal information using Google Analytics or associating personal information with web*/}
                        {/*        analytics information</p></td>*/}
                        {/*    <td><p><a*/}
                        {/*        href="http://www.google.co.uk/intl/en/analytics/privacyoverview.html">Click here for an*/}
                        {/*        overview of privacy at Google</a></p></td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td valign="top" align="center"><p align="left">F5</p></td>
                            <td valign="top" align="left">NL001</td>
                            <td valign="top" align="left">Session based (expires when you leave the Nemo website).</td>
                            <td>Nemo’s website is load balanced across 2 servers which means that some visitors view the
                                site on one server, and others see it on another server. This cookie uniquely identifies
                                you and ensures your requests on our site are always directed to the same server during
                                the same session.
                            </td>
                            <td valign="top" align="left"></td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td valign="top" align="center"><p align="left">Website code</p></td>*/}
                        {/*    <td valign="top" align="left">sifrFETCH</td>*/}
                        {/*    <td>Session based (expires when you leave the Nemo website).</td>*/}
                        {/*    <td>Nemo's website uses sIFR fonts for headings and these are based on Flash.</td>*/}
                        {/*    <td valign="top" align="left"></td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>&nbsp;</td>*/}
                        {/*    <td>&nbsp;ASP.NET_SessionId</td>*/}
                        {/*    <td>Session based (expires when you leave the Nemo website).</td>*/}
                        {/*    <td>&nbsp;This cookie is used to set your session ID when you enter the Nemo website.</td>*/}
                        {/*    <td>&nbsp;</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>&nbsp;</td>*/}
                        {/*    <td>&nbsp;cookieMessage</td>*/}
                        {/*    <td>Session based (expires when you leave the Nemo website).</td>*/}
                        {/*    <td>&nbsp;This cookies is related to the cookie message we display when you come onto our*/}
                        {/*        site.*/}
                        {/*    </td>*/}
                        {/*    <td>&nbsp;</td>*/}
                        {/*</tr>*/}
                        </tbody>
                    </table>
                    <br />Please note that third parties (including, for example, advertising networks and providers of
                        external services like web traffic analysis services) may also use cookies, over which we have
                        no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
                        <p></p>
                        <p className="NormalSpaced" style={{ margin: '0cm 0cm 12pt' }}>You block cookies by activating the
                            setting on your browser that allows you to refuse the setting of all or some cookies.
                            However, if you use your browser settings to block all cookies (including essential cookies)
                            you may not be able to access all or parts of our site. <br /><br />Except for essential
                                cookies, all cookies will expire after 1 month. <br /><br />If you would like to find out
                                    more about cookies and how to remove them from your computer, please visit <a
                                        href="http://www.allaboutcookies.org/"
                                        target="_blank" rel="noreferrer">www.allaboutcookies.org</a>&nbsp; <br /><br />To opt out of being
                                        tracked by Google Analytics across all websites visit <a
                                            href="http://tools.google.com/dlpage/gaoptout"
                                            target="_blank" rel="noreferrer">http://tools.google.com/dlpage/gaoptout</a>.<br /></p>

                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default CookiesPage;